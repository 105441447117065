import React, { useEffect } from "react";
import { Box, Button, Grid, Input, Typography, TextField, Snackbar, IconButton, Alert } from "@mui/material";
import Dialog from '@mui/material/Dialog';
import { useFormik } from "formik";
import { CreateLeadWithoutTokenService } from "../Redux/Services/service";
import { makeStyles } from '@mui/styles';
import * as Yup from 'yup';
import { getVcfFileLink } from "../Redux/Services/MarketingServices";
import Slide, { SlideProps } from '@mui/material/Slide';

const useStyles = makeStyles((theme) => ({
    dialogPaper: {
        borderRadius: 50, // Adjust the value as needed
    },
}));

const isMobile = window.innerWidth < 991;


const validationSchema = Yup.object().shape({
    name: Yup.string()
        .required('Name is required')
        .min(2, 'Name must be at least 2 characters')
        .max(50, 'Name must be less than 50 characters'),
    email: Yup.string()
        .email('Invalid email')
        .required('Email is required'),
});

export const FlotingPopup = ({ CardDetails }) => {
    const classes = useStyles();



    const [open, setOpen] = React.useState(false)
    const toggleDialog = () => setOpen(!open);
    const [contactLink, setConactLink] = React.useState("")
    const [severity, setSeverity] = React.useState("info")

    const [snackMsg, setSnackMsg] = React.useState("")
    const [snackBarOpen, setSnackBarOpen] = React.useState(false)
    const toogleSnack = () => setSnackBarOpen(!snackBarOpen);
    const [loading, setLoading] = React.useState(false)

    function SlideTransition(props) {
        return <Slide {...props} direction="up" />;
    }

    const submitLead = () => {
        setLoading(true)
        const data = {
            type: "O",
            lead_type: "W",
            first_name: formik.values?.name,
            last_name: "none",
            mobile_1: formik.values?.phone,
            mobile_2: "",
            mobile_3: "",
            email: formik.values?.email,
            email_2: "",
            address_1: "",
            address_2: "",
            full_address: "fulladdress",
            city: "",
            state: "",
            zip_code: "",
            source_id: 88,
            agent_id: "",
            listing_agent: "",
            company_attorney: "",
            loan_officer: "",
            insurance_agent: "",
            label: "label",
            notes: formik.values?.note,
            price_range: "",
            target_city: "",
            closing_date: "",
            bedroom: "",
            bathroom: "",
            garage: "",
            custom_tag: "",
            nurture: "",
            tag: "",
            summary: "",
            openfield1: "",
            openfield2: "",
            openfield3: "",
            openfield4: "",
            openfield5: "",
            openfield6: "",
            openfield7: "",
            openfield8: "",
            openfield9: "",
            openfield10: "",
            is_active: "Y",
            is_arch: "N",
            is_verified: "N",
            created_by: CardDetails?.created_by
            // created_by: 1
        }
        formik.setFieldTouched("name")
        formik.setFieldTouched("email")
        formik.setFieldTouched("phone")
        formik.setFieldTouched("note")
        console.log(formik.errors)
        CreateLeadWithoutTokenService(data)
            .then((res) => {
                setLoading(false)

                if (res.status === 200) {
                    toggleDialog();
                    toogleSnack();
                    setSeverity("success")
                    setSnackMsg("Successfully added your details!")
                    // alert("lead created")
                    setTimeout(() => {
                        setSnackBarOpen(false)
                    }, 3000);
                }
            })
            .catch((err) => {
                setLoading(false)
            });

    }



    const formik = useFormik({
        initialValues: {
            name: "",
            email: "",
            phone: "",
            note: ""
        },
        validationSchema: validationSchema,
        validateOnBlur: true
        , onSubmit: submitLead
    })

    const AddtoContact = async () => {

        // console.log(visintingDetail.id)
        const response = await getVcfFileLink(CardDetails.id)
        // window.location.href = response.data.File_url;
        setConactLink(response.data.File_url)
    }

    React.useEffect(() => {
        AddtoContact()
    }, [CardDetails])


    return <>
        <Box className="" sx={{ position: "absolute", top: 10, right: 10, zIndex: 99999 }}>

            <Grid onClick={toggleDialog} style={{
                backgroundColor: "#F4F0F0",
                borderRadius: 100,
                width: "1.8em", height: "1.8em",
                padding: 4
            }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path fill={CardDetails?.color} d="m5.8 18l.9.9q.275.275.275.7t-.275.7t-.7.275t-.7-.275l-2.6-2.6q-.3-.3-.3-.7t.3-.7l2.6-2.6q.275-.275.7-.275t.7.275t.275.7t-.275.7l-.9.9h12.4l-.9-.9q-.275-.275-.275-.7t.275-.7t.7-.275t.7.275l2.6 2.6q.3.3.3.7t-.3.7l-2.6 2.6q-.275.275-.7.275t-.7-.275t-.275-.7t.275-.7l.9-.9zM2 11v-.575q0-.6.325-1.1t.9-.75q.65-.275 1.338-.425T6 8t1.438.15t1.337.425q.575.25.9.75t.325 1.1V11zm12 0v-.575q0-.6.325-1.1t.9-.75q.65-.275 1.338-.425T18 8t1.438.15t1.337.425q.575.25.9.75t.325 1.1V11zM6 7q-.825 0-1.412-.587T4 5t.588-1.412T6 3t1.413.588T8 5t-.587 1.413T6 7m12 0q-.825 0-1.412-.587T16 5t.588-1.412T18 3t1.413.588T20 5t-.587 1.413T18 7" /></svg>
            </Grid>


            {/* {isMobile && parseInt(CardDetails?.theme) == 1 && !open ?
                <Box onClick={() => { window.location.href = contactLink }} sx={{ background: "#F4F0F0", borderRadius: 100, padding: 1, width: "1.1em", height: "1.1em", marginTop: 1 }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="1.1em" height="1.1em" viewBox="0 0 24 24"><path fill={CardDetails?.color} d="M21 7v12q0 .825-.587 1.413T19 21H5q-.825 0-1.412-.587T3 19V5q0-.825.588-1.412T5 3h12zm-9 11q1.25 0 2.125-.875T15 15t-.875-2.125T12 12t-2.125.875T9 15t.875 2.125T12 18m-6-8h9V6H6z" /></svg>
                </Box> : null} */}

            <div style={{}}>
                <Snackbar TransitionComponent={SlideTransition} anchorOrigin={{ vertical: 'bottom', horizontal: 'center', }} open={snackBarOpen} action={
                    <React.Fragment>
                        <IconButton
                            size="small"
                            aria-label="close"
                            color="inherit"
                            onClick={() => {
                                setOpen(false)
                            }}
                        >
                            x
                        </IconButton>
                    </React.Fragment>
                } autoHideDuration={6000} sx={{ zIndex: 100 }}>
                    <Box sx={{ background: "#fff", padding: 2, borderRadius: 5 }}>
                        <Typography color={"#595859"}>{snackMsg}</Typography>
                    </Box>
                    {/* <Alert severity={severity} sx={{ width: '100%' }}>
                        {snackMsg}
                    </Alert> */}
                </Snackbar>
            </div>
        </Box>

        <Dialog open={open} onClose={toggleDialog} classes={{ paper: classes.dialogPaper }}>
            <Box sx={{}} className="bg-form">
                <div className="overlay-dark" />
                <Grid container sx={{ padding: isMobile ? 2 : 5 }}>
                    <Grid item xs={12} justifyContent={"center"} alignSelf={"center"} display={"flex"}>
                        <img
                            alt=""
                            src={
                                CardDetails?.profile_photo
                                    ? `${CardDetails.profile_photo}`
                                    : process.env.PUBLIC_URL + "/assests/Image/avatar.jpg"
                            }
                            width={isMobile ? "30%" : "20%"}
                            height="100%"
                            style={{ borderRadius: 20, objectFit: "cover" }}

                        />
                    </Grid>
                    <Grid item xs={12} justifyContent={"center"} alignSelf={"center"} mx={2} display={"flex"}>
                        <Box sx={{ alignItems: "center", alignSelf: "center" }}>
                            <div
                                style={{
                                    paddingRight: 10,
                                    fontWeight: 700,
                                    fontSize: isMobile ? "16px" : "24px",
                                    color: `${"#fff"}`,
                                    // lineHeight: "113.9%",
                                    textAlign: "center"
                                }}
                            >
                                {"Connect with " + CardDetails?.first_name + " !"}

                            </div>

                            <div className="visiting-divider" style={{ backgroundColor: "#fff", width: "100%" }}></div>

                            <div style={{
                                marginTop: 10,
                                fontWeight: 600,
                                fontSize: isMobile ? "12px" : "14px",
                                color: `#fff`,
                                lineHeight: "128.9%",
                                textAlign: "center",

                            }}>
                                {"Please provide your information below."}
                            </div>
                        </Box>
                    </Grid>
                </Grid>
                <div style={{ padding: isMobile ? "0" : "15px" }} className="funnel3-form-content">

                    <TextField
                        sx={{ padding: 1, width: isMobile ? "85%" : "60%", background: "rgba(0,0,0,0)" }}
                        id="input-with-icon-textfield"
                        placeholder="Enter your name"
                        onChange={(e) => formik.setFieldValue("name", e.target.value)}
                        helperText={<Typography style={{ color: "orange", fontSize: 10, }}>{formik.errors.name}</Typography>}
                        InputProps={{
                            sx: { borderRadius: 3, background: "#fff" },
                            disableUnderline: true,
                            startAdornment: (
                                <div style={{ padding: 5, marginRight: 8, justifyContent: "center", alignSelf: "center" }}>
                                    <svg
                                        width={isMobile ? "15" : "25"}
                                        height={isMobile ? "15" : "25"}
                                        viewBox="0 0 30 30"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M20.625 11.25C20.625 12.7418 20.0324 14.1726 18.9775 15.2275C17.9226 16.2824 16.4918 16.875 15 16.875C13.5082 16.875 12.0774 16.2824 11.0225 15.2275C9.96763 14.1726 9.375 12.7418 9.375 11.25C9.375 9.75816 9.96763 8.32742 11.0225 7.27252C12.0774 6.21763 13.5082 5.625 15 5.625C16.4918 5.625 17.9226 6.21763 18.9775 7.27252C20.0324 8.32742 20.625 9.75816 20.625 11.25Z"
                                            fill="black"
                                        />
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M0 15C0 11.0218 1.58035 7.20644 4.3934 4.3934C7.20644 1.58035 11.0218 0 15 0C18.9782 0 22.7936 1.58035 25.6066 4.3934C28.4196 7.20644 30 11.0218 30 15C30 18.9782 28.4196 22.7936 25.6066 25.6066C22.7936 28.4196 18.9782 30 15 30C11.0218 30 7.20644 28.4196 4.3934 25.6066C1.58035 22.7936 0 18.9782 0 15ZM15 1.875C12.5283 1.87513 10.1069 2.57318 8.0145 3.8888C5.92207 5.20442 4.24366 7.08414 3.17243 9.31161C2.10121 11.5391 1.68072 14.0238 1.95937 16.4797C2.23802 18.9356 3.20447 21.2629 4.7475 23.1937C6.07875 21.0487 9.00938 18.75 15 18.75C20.9906 18.75 23.9194 21.0469 25.2525 23.1937C26.7955 21.2629 27.762 18.9356 28.0406 16.4797C28.3193 14.0238 27.8988 11.5391 26.8276 9.31161C25.7563 7.08414 24.0779 5.20442 21.9855 3.8888C19.8931 2.57318 17.4717 1.87513 15 1.875Z"
                                            fill="black"
                                        />
                                    </svg>
                                </div>

                            ),
                        }}
                    // variant="standard"
                    />

                    <TextField
                        sx={{ padding: 1, width: isMobile ? "85%" : "60%", background: "rgba(0,0,0,0)" }}
                        // id="input-with-icon-textfield"
                        placeholder="Enter your email"
                        onChange={(e) => formik.setFieldValue("email", e.target.value)}
                        helperText={<Typography style={{ color: "orange", fontSize: 10, }}>{formik.errors.email}</Typography>}
                        InputProps={{
                            disableUnderline: true,
                            sx: { borderRadius: 3, background: "#fff" },
                            startAdornment: (
                                <div style={{ padding: 5, marginRight: 8, justifyContent: "center", alignSelf: "center" }}>
                                    <svg
                                        width={isMobile ? "15" : "24"}
                                        height={isMobile ? "11" : "15"}
                                        viewBox="0 0 30 23"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M30 2.875C30 1.29375 28.65 0 27 0H3C1.35 0 0 1.29375 0 2.875V20.125C0 21.7062 1.35 23 3 23H27C28.65 23 30 21.7062 30 20.125V2.875ZM27 2.875L15 10.0625L3 2.875H27ZM27 20.125H3V5.75L15 12.9375L27 5.75V20.125Z"
                                            fill="black"
                                        />
                                    </svg>
                                </div>

                            ),
                        }}
                    // variant="standard"
                    />

                    <TextField
                        sx={{ padding: 1, width: isMobile ? "85%" : "60%", background: "rgba(0,0,0,0)", textAlignVertical: "top", }}
                        // id="input-with-icon-textfield"
                        placeholder="Enter your phone"

                        onChange={(e) => formik.setFieldValue("phone", e.target.value)}
                        helperText={<Typography style={{ color: "red", fontSize: 10, }}>{formik.errors.phone}</Typography>}
                        InputProps={{

                            disableUnderline: true,
                            sx: { borderRadius: 3, background: "#fff" },
                            startAdornment: (
                                <div style={{ padding: 5, marginRight: 8, justifyContent: "center", alignSelf: "center" }}>
                                    <svg
                                        width={isMobile ? "14" : "18"}
                                        height={isMobile ? "14" : "18"}
                                        viewBox="0 0 26 26"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M24.4833 26C21.3778 26 18.3507 25.3076 15.4021 23.9229C12.4526 22.5391 9.84052 20.7095 7.566 18.434C5.29052 16.1595 3.46089 13.5474 2.07711 10.5979C0.692371 7.6493 0 4.62222 0 1.51667C0 1.08333 0.144444 0.722222 0.433333 0.433333C0.722222 0.144444 1.08333 0 1.51667 0H7.36667C7.7037 0 8.00463 0.108333 8.26944 0.325C8.53426 0.541667 8.69074 0.818518 8.73889 1.15556L9.67778 6.21111C9.72593 6.54815 9.72015 6.85485 9.66044 7.13122C9.59978 7.40856 9.46111 7.65556 9.24444 7.87222L5.77778 11.4111C6.78889 13.1444 8.05278 14.7694 9.56944 16.2861C11.0861 17.8028 12.7593 19.1148 14.5889 20.2222L17.9833 16.8278C18.2 16.6111 18.4831 16.4484 18.8327 16.3396C19.1813 16.2317 19.5241 16.2019 19.8611 16.25L24.8444 17.2611C25.1815 17.3333 25.4583 17.4956 25.675 17.7479C25.8917 18.0011 26 18.2963 26 18.6333V24.4833C26 24.9167 25.8556 25.2778 25.5667 25.5667C25.2778 25.8556 24.9167 26 24.4833 26Z"
                                            fill="black"
                                        />
                                    </svg>
                                </div>

                            ),
                        }}
                    // variant="standard"
                    />


                    <TextField
                        sx={{ padding: 1, width: isMobile ? "85%" : "60%", backgroundColor: "rgba(0,0,0,0)" }}
                        placeholder="Leave a message"
                        multiline
                        rows={1}
                        onChange={(e) => formik.setFieldValue("note", e.target.value)}
                        helperText={<Typography style={{ color: "red", fontSize: 10, }}>{formik.errors.note}</Typography>}
                        InputProps={{
                            disableUnderline: true,
                            sx: { borderRadius: 3, background: "#fff" },
                            startAdornment: (
                                <div style={{ padding: 0, marginRight: 8, justifyContent: "center", alignSelf: "center" }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width={isMobile ? "20" : "20"}
                                        height={isMobile ? "25" : "20"} viewBox="0 0 56 56"><path fill="currentColor" d="M15.555 53.125h24.89c4.852 0 7.266-2.461 7.266-7.336V24.508c0-3.024-.328-4.336-2.203-6.258L32.57 5.102c-1.78-1.829-3.234-2.227-5.882-2.227H15.555c-4.828 0-7.266 2.484-7.266 7.36v35.554c0 4.898 2.438 7.336 7.266 7.336m.187-3.773c-2.414 0-3.68-1.29-3.68-3.633V10.305c0-2.32 1.266-3.657 3.704-3.657h10.406v13.618c0 2.953 1.5 4.406 4.406 4.406h13.36v21.047c0 2.343-1.243 3.633-3.68 3.633ZM31 21.132c-.914 0-1.29-.374-1.29-1.312V7.375l13.5 13.758Zm5.625 9.985h-17.79c-.843 0-1.452.633-1.452 1.43c0 .82.61 1.453 1.453 1.453h17.789a1.43 1.43 0 0 0 1.453-1.453c0-.797-.633-1.43-1.453-1.43m0 8.18h-17.79c-.843 0-1.452.656-1.452 1.476c0 .797.61 1.407 1.453 1.407h17.789c.82 0 1.453-.61 1.453-1.407c0-.82-.633-1.476-1.453-1.476" /></svg>

                                </div>

                            ),
                        }}
                    // variant="standard"
                    />
                    <div style={{ marginTop: 10, width: isMobile ? "80%" : "58%" }}>
                        <Button disabled={loading} onClick={formik.handleSubmit} name="Submit" sx={{ background: "linear-gradient(40deg, #8A58F5,#4643BE ) !important", color: "white", height: "45px !important", marginTop: 1, width: isMobile ? "100%" : "100%", fontWeight: "600", marginBottom: 5, textTransform: "none", borderRadius: 3 }}>
                            Share My Details!
                        </Button>
                    </div>
                </div>
            </Box>
        </Dialog >
    </>
}