import React, { useReducer } from "react"
import { GetCardByIdService, GetCardByNameService, getFunnelDetailsByAPI, getUserListingByKastnameAPI } from "../Redux/Services/MarketingServices";
import { useParams } from "react-router-dom";
import { CreateLeadWithoutTokenService } from "../Redux/Services/service";
import { Alert, Grid, IconButton, Snackbar, Typography } from "@mui/material";
import moment from "moment";
import TestimonialSection from "./listing-slider-component";
const isMobile = window.innerWidth < 991;
const defaultImage = "https://cdn.vectorstock.com/i/preview-1x/52/88/no-image-symbol-shadow-missing-available-icon-vector-46405288.jpg"


export const LightLandingPage = ({ visintingDetail, mainFunnelData }) => {

    const { card_id } = useParams();
    const [cardDetails, setCardDetails] = React.useState(visintingDetail)
    const [loading, setLoading] = React.useState(false)
    const [lisitng_item, setLisitng_item] = React.useState([]);
    const [severity, setSeverity] = React.useState("info")
    const [snackMsg, setSnackMsg] = React.useState("")
    const [subEmail, setSubEmail] = React.useState("")
    const [inputError, setInputError] = React.useState();
    const [open, setOpen] = React.useState(false);
    const [Submitloader, setSubmitLoader] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false)
    const [activePanel, setActivePanel] = React.useState(0);

    const handlePanelClick = (panelId) => {
        setActivePanel(panelId); // Set the clicked panel as active
    };

    React.useEffect(() => {
        getUserListingByKastnameAPI(card_id)
            .then((res) => {
                setLisitng_item(res?.data?.listings);
            })
            .catch((err) => {
                console.log("err lisitng", err);
            });
    }, []);



    const handleLoginEmailFormValidation = () => {
        let formErrors = {};
        let formIsValid = true;

        if (!subEmail) {
            formIsValid = false;
            formErrors["email_error"] = "Email field is required.";
        } else if (!/\S+@\S+\.\S+/.test(subEmail)) {
            formIsValid = false;
            formErrors["email_error"] = "Invalid Email id.";
        }


        setInputError(formErrors);
        return formIsValid;
    };


    const submitLead = () => {

        if (handleLoginEmailFormValidation()) {

            const data = {
                type: "O",
                lead_type: "C",
                first_name: "none",
                last_name: "none",
                mobile_1: "none",
                mobile_2: "",
                mobile_3: "",
                email: subEmail,
                email_2: "",
                address_1: "",
                address_2: "",
                full_address: "fulladdress",
                city: "",
                state: "",
                zip_code: "",
                source_id: 64,
                agent_id: "",
                listing_agent: "",
                company_attorney: "",
                loan_officer: "",
                insurance_agent: "",
                label: "label",
                notes: "",
                price_range: "",
                target_city: "",
                closing_date: "",
                bedroom: "",
                bathroom: "",
                garage: "",
                custom_tag: "",
                nurture: "",
                tag: "",
                summary: "",
                openfield1: "",
                openfield2: "",
                openfield3: "",
                openfield4: "",
                openfield5: "",
                openfield6: "",
                openfield7: "",
                openfield8: "",
                openfield9: "",
                openfield10: "",
                is_active: "Y",
                is_arch: "N",
                is_verified: "N",
                created_by: cardDetails?.created_by


            }

            CreateLeadWithoutTokenService(data)
                .then((res) => {
                    if (res.status === 200) {
                        setOpen(true)
                        setSeverity("success")
                        setSnackMsg("Successfully added!")
                        setSubmitLoader(false)
                        setIsLoading(false)

                        setTimeout(() => {
                            setOpen(false)
                        }, 3000);

                    }
                })
                .catch((err) => {
                    setOpen(true)
                    setSeverity("error")
                    setSnackMsg("Error!")
                    setSubmitLoader(false)
                    //.log("error", err);
                    setIsLoading(false)

                });
        }

    }

    const items = visintingDetail?.social_details?.filter(_i => _i.type == "R")


    return <div className="section-wrapper" >
        <section id="about" className="bg-one authorv2" style={{ height: "initial" }}>
            <div className="container" style={{ overflowY: "hidden", }}>
                <div className="row">
                    <div className="col-xl-6 offset-xl-3 col-lg-10 offset-lg-1">
                        <div className="section-title-center text-center">
                            <span
                                data-aos="fade-up"
                                data-aos-duration="1000"
                                data-aos-delay="150"
                            >About me</span>
                            <h2
                                className="display-6"
                                data-aos="fade-up"
                                data-aos-duration="1000"
                                data-aos-delay="200"
                            >
                                Learn about the book writer
                            </h2>
                            <div
                                className="section-divider divider-traingle"
                                data-aos="fade-up"
                                data-aos-duration="1000"
                                data-aos-delay="250"
                            ></div>
                        </div>
                    </div>
                </div>
                <div className="row gx-5">
                    <div
                        className="col-lg-6 mb-4 mb-lg-0"
                        data-aos="fade-right"
                        data-aos-duration="1000"
                        data-aos-delay="200"
                    >
                        <div className="authorv2__image">
                            <img
                                className="img-fluid"
                                src={cardDetails?.profile_photo ?? "/lightTheme/assets/images/achive.png"}
                                alt="Author Image"
                                style={{ aspectRatio: 1.1 }}
                            />
                        </div>
                    </div>
                    <div
                        className="col-lg-6"
                        data-aos="fade-left"
                        data-aos-duration="1000"
                        data-aos-delay="200"
                    >
                        <p>
                            {cardDetails?.tagline}
                        </p>
                        <div className="authorv2__content">
                            <div className="authorv2__list">
                                <div className="row">
                                    <div className="col-6 mb-3">
                                        <h4>Name:</h4>
                                        <p className="mb-0">{cardDetails?.first_name}</p>
                                    </div>
                                    <div className="col-6 mb-3">
                                        <h4>Mobile:</h4>
                                        <p className="mb-0">{cardDetails?.mobile}</p>
                                    </div>
                                    <div className="col-6 mb-3">
                                        <h4>Office:</h4>
                                        <p className="mb-0">{cardDetails?.officePhone}</p>
                                    </div>
                                    <div className="col-6 mb-3">
                                        <h4>Email ID:</h4>
                                        <p className="mb-0">{cardDetails?.email}</p>
                                    </div>
                                    <div className="col-6 mb-3">
                                        <h4>Job Title:</h4>
                                        <p className="mb-0">{cardDetails?.job_title}</p>
                                    </div>
                                    <div className="col-6">
                                        <h4>Company Name:</h4>
                                        <p className="mb-0">{cardDetails?.company_name}</p>
                                    </div>
                                </div>
                            </div>
                            <ul className="social-icon mt-3">
                                <li>
                                    <a href={cardDetails?.social_details?.filter(_i => _i.type == "F")[0]?.url}><i className="icofont-facebook"></i></a>
                                </li>
                                {/* <li>
                                <a href={cardDetails?.social_details?.filter(_i => _i.type == "T")[0]?.url}><i className="icofont-twitter"></i></a>
                            </li> */}
                                <li>
                                    <a href={cardDetails?.social_details?.filter(_i => _i.type == "L")[0]?.url}><i className="icofont-linkedin"></i></a>
                                </li>
                                <li>
                                    <a href={cardDetails?.social_details?.filter(_i => _i.type == "I")[0]?.url}> <i className="icofont-instagram"></i></a>
                                </li>
                                {/* <li>
                                <a href={cardDetails?.social_details?.filter(_i => _i.type = "I")[0]?.url}><i className="icofont-whatsapp"></i></a>
                            </li> */}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            {/* book section */}
        </section>
        <section id="books" className="booksv1  bg-one">
            <div className="container" style={{ overflowY: "hidden", }}>
                <div className="row">
                    <div className="col-xl-6 offset-xl-3 col-lg-10 offset-lg-1">
                        <div className="section-title-center text-center">
                            <span
                                data-aos="fade-up"
                                data-aos-duration="1000"
                                data-aos-delay="150"
                            >Books</span>
                            <h2
                                className="display-6"
                                data-aos="fade-up"
                                data-aos-duration="1000"
                                data-aos-delay="200"
                            >
                                External Links
                            </h2>
                            <div
                                className="section-divider divider-traingle"
                                data-aos="fade-up"
                                data-aos-duration="1000"
                                data-aos-delay="250"
                            ></div>
                        </div>
                    </div>
                </div>
                <div className="row gx-5">

                    <div
                        className="col-lg-12"
                        data-aos="fade-left"
                        data-aos-duration="1000"
                        data-aos-delay="200"
                    >
                        <div className="booksv1__contnet">
                            <div className="booksv1__contnet--badge mb-0">
                                Best selling books
                            </div>
                            <h3 className="display-6 m-30px-b">Books released in 2022</h3>
                            <div className="booksv1__item--wrap">

                                {cardDetails?.social_details?.filter(_i => _i.type == "E").map((_item, i) => {
                                    console.log("hey", _item.id, _item.title)
                                    return <div key={i} className="booksv1__item" onClick={() => window.location.href = _item.url}>
                                        <div className="booksv1__item--icon">
                                            <i className="icofont-touch"></i>
                                        </div>
                                        <div className="booksv1__item--text " style={{ justifyContent: "center", alignSelf: "center" }}>
                                            <h3 className="m-0">{_item.title}</h3>
                                        </div>
                                    </div>
                                })}

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {lisitng_item?.length > 0 &&
            <section className="books  bg-one">
                <div className="container" style={{ overflowY: "hidden" }}>
                    <div className="row">
                        <div className="col-xl-6 offset-xl-3 col-lg-10 offset-lg-1">
                            <div className="section-title-center text-center">
                                <span
                                    data-aos="fade-up"
                                    data-aos-duration="1000"
                                    data-aos-delay="150"
                                >Books</span>
                                <h2
                                    className="display-6"
                                    data-aos="fade-up"
                                    data-aos-duration="1000"
                                    data-aos-delay="200"
                                >
                                    Listings & Open Houses
                                </h2>
                                <div
                                    className="section-divider divider-traingle"
                                    data-aos="fade-up"
                                    data-aos-duration="1000"
                                    data-aos-delay="250"
                                ></div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <TestimonialSection lisitng_item={lisitng_item} cardDetails={cardDetails} />
                    </div>
                </div>
            </section>}



        <section id="preview" className="section-padding chapter-preview bg-one" style={{ height: "auto" }}>
            <div className="container" style={{ overflowY: "hidden" }}>
                <div className="row">
                    <div className="col-xl-6 offset-xl-3 col-lg-10 offset-lg-1">
                        <div className="section-title-center text-center">
                            <span data-aos="fade-up" data-aos-duration="1000" data-aos-delay="150">Books</span>
                            <h2 className="display-6" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="200">
                                Free Downloads
                            </h2>
                            <div className="section-divider divider-traingle" data-aos="fade-up" data-aos-duration="1000"
                                data-aos-delay="250"></div>
                        </div>
                    </div>
                </div>
                <div className="chapter__preview2" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="300" >
                    <div className="chapter__preview2-content" >
                        <div className="chapter__preview2-container" style={{ height: isMobile ? "initial" : "80vh", overflow: "hidden" }}>

                            {items?.map((item, i) => {
                                const url = item.url;
                                const image = mainFunnelData[url?.includes("/") ? url.split("/")[0] : url]?.uri == "" ? defaultImage : mainFunnelData[url?.includes("/") ? url.split("/")[0] : url]?.uri

                                return <div key={i} className={`panel ${activePanel === i ? 'active' : ''}`} onClick={() => handlePanelClick(i)} style={{ backgroundImage: `url(${image})` ?? "", }}>
                                    <a data-glightbox="description: .custom-desc1; descPosition: right;" className="glightbox2"
                                        onClick={() => window.location.href = "/" + visintingDetail?.kast_name + `/${mainFunnelData[url.includes("/") ? url.split("/")[0] : url].data.url}/`}
                                    // href={"/" + visintingDetail?.kast_name + `/${mainFunnelData[item.url.includes("/") ? item.url.split("/")[0] : item.url].data.url}/` ?? ""}
                                    >
                                    </a>
                                    <h3>{i}</h3>
                                    {/* <h3>{mainFunnelData[url.includes("/") ? url.split("/")[0] : url]?.title}</h3> */}
                                </div>
                            })}

                        </div>
                    </div>
                </div>
            </div>
        </section >


        <section className="cta " id="cta">
            <div className="container" style={{ overflowY: "hidden" }}>
                <div className="row">
                    <div className="cta__inner bg-one">
                        <div className="row justify-content-center">
                            <div className="col-lg-8">
                                <div className="section-title-center mb-0 text-center">
                                    <span
                                        data-aos="fade-up"
                                        data-aos-duration="1000"
                                        data-aos-delay="150"
                                    >SUBSCRIBE NOW</span>
                                    <h2
                                        className="display-6"
                                        data-aos="fade-up"
                                        data-aos-duration="1000"
                                        data-aos-delay="200"
                                        
                                    >
                                        Get updates of my new released books
                                    </h2>
                                </div>
                                <div className="cta-form-box">

                                    {/* <form
                                        action="assets/subscribe/subscribe.php"
                                        id="subscribe2"
                                    > */}
                                    <div
                                        className="mb13"
                                        data-aos="fade-up"
                                        data-aos-duration="1000"
                                        data-aos-delay="250"
                                    >
                                        <input
                                            className="cta-email"
                                            type="email"
                                            name="email"
                                            placeholder="Your Email"
                                            id="subscriber-email"
                                            onChange={(e) => { setSubEmail(e.target.value) }}
                                        />
                                    </div>
                                    <button
                                        className="w-100 button button__primary align-items-center"
                                        id="subscribe-button"
                                        data-aos="fade-up"
                                        data-aos-duration="1000"
                                        data-aos-delay="300"
                                        onClick={submitLead}
                                    >
                                        <span
                                        >Subscribe Now <i className="icofont-arrow-right"></i></span>
                                    </button>
                                    <div className="result">
                                        <p
                                            className="success-msg"
                                            data-aos="fade-up"
                                            data-aos-duration="1000"
                                            data-aos-delay="350"
                                        >
                                            <i className="icofont-check"></i> Your email has been
                                            stored!
                                        </p>
                                        <p
                                            className="error-msg"
                                            data-aos="fade-up"
                                            data-aos-duration="1000"
                                            data-aos-delay="400"
                                        >
                                            <i className="icofont-close"></i> Sorry! Something went
                                            wrong!
                                        </p>
                                    </div>
                                    {/* </form> */}


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <div style={{ width: "100vh" }}>
            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} open={open} action={
                <React.Fragment>
                    <IconButton
                        size="small"
                        aria-label="close"
                        color="inherit"
                        onClick={() => {
                            setOpen(false)
                        }}
                    >
                        x
                    </IconButton>
                </React.Fragment>
            } autoHideDuration={6000} sx={{ zIndex: 100 }}>
                <Alert severity={severity} sx={{ width: '100%' }}>
                    {snackMsg}
                </Alert>
            </Snackbar>
        </div>

    </div >

}
