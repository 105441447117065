import React, { useEffect, useState } from "react";
import MessageIcon from "../SVG/MessageIcon";
import PhoneIconSvg, { Telephone } from "../SVG/PhoneIconSvg";
import VisitingInsta from "../SVG/VisitingInsta";
import VisitingLinkdin from "../SVG/VisitingLinkdin";
import VisitingFacebook from "../SVG/VisitingFacebook";
import { Alert, Box, Button, CircularProgress, Grid, IconButton, Input, Snackbar, Typography } from "@mui/material";
import { GetCardByIdService, GetCardByNameService, getFunnelDetailsByAPI, getUserListingByKastnameAPI, sendNotificationAPI } from "../Redux/Services/MarketingServices";
import { useParams } from "react-router-dom";
import VisitingTwitter from "../SVG/VisitingTwitter";
import VisitingWebsite from "../SVG/VisitingWebsite";
import LocationIcon from "../SVG/LocationIcon";
import QRCode from 'qrcode.react';
import HeartEmoji from "../SVG/HeartEmoji";
import { Buffer } from 'buffer';
import { AddNewLeadService, checkValidationForKastIDAPI, getScriptByUser, getScriptByUserID, getSubscribeSerive } from "../Redux/Services/service";
import { CreateLeadWithoutTokenService } from "../Redux/Services/service";
import { Helmet } from 'react-helmet';
import KastPreview from "./kast-preview/kast-preview";
import moment from "moment";
import { FlotingPopup } from "./floating-contact";
import { LightLandingPage } from "./LightLandingPage";


const dummyFunnelData = {
  "funnel-2": {
    title: "Home Buying 101 - A Complete Guide",
    uri: "https://bizooda.s3.us-east-2.amazonaws.com/aaassets/Buyer-101+Guide.png",
    data: {
      url: `Buying101Guide`,
      title: "Buyer",
      type: "R",
      is_active: "Y",
    },
  },
  "funnel-3": {
    title: "18 Insider Secrets To Selling Your Home",
    uri: "https://bizooda.s3.us-east-2.amazonaws.com/aaassets/18+Insider+Home+Selling+Secrets+-eBook.png",
    data: {
      url: `SellingInsiderSecrets`,
      title: "Seller",
      type: "R",
      is_active: "Y",
    },
  },
  "funnel-1": {
    title: "The Ultimate Home Seller Checklist",
    uri: "https://bizooda.s3.us-east-2.amazonaws.com/aaassets/Home%2BSeller%2BChecklist%2BEBook.png",
    data: {
      url: `HomeSellerChecklist`,
      title: "Seller",
      type: "R",
      is_active: "Y",
    },
  },
}

const isMobile = window.innerWidth < 991;

function VisitingCard() {
  const { card_id } = useParams();
  const [visintingDetail, setVisintingDetail] = useState();
  const [loading, setLoading] = React.useState(true);
  const [isLoading, setIsLoading] = React.useState(false)
  const [inputData, setInputData] = useState({});
  const [inputError, setInputError] = useState();
  const [Submitloader, setSubmitLoader] = useState(false);
  const [open, setOpen] = useState(false);
  const [metaTag, setMetaTag] = useState(dummyFunnelData);


  const [lisitng_item, setLisitng_item] = useState([]);

  const [severity, setSeverity] = useState("info")

  const [snackMsg, setSnackMsg] = useState("")


  // console.log(visintingDetail?.social_details.filter(item => item.type == "R"));
  const [BackFunnel, setBackFunnel] = useState([]);
  const [mainFunnelData, setMainFunnelData] = useState({})




  const handleLoginEmailFormValidation = () => {
    let formErrors = {};
    let formIsValid = true;

    if (!inputData?.email) {
      formIsValid = false;
      formErrors["email_error"] = "Email field is required.";
    } else if (!/\S+@\S+\.\S+/.test(inputData?.email)) {
      formIsValid = false;
      formErrors["email_error"] = "Invalid Email id.";
    }


    setInputError(formErrors);
    return formIsValid;
  };

  const submitLead = () => {

    if (handleLoginEmailFormValidation()) {
      setIsLoading(true)
      setSubmitLoader(true)

      const data = {
        type: "O",
        lead_type: "C",
        first_name: "none",
        last_name: "none",
        mobile_1: "none",
        mobile_2: "",
        mobile_3: "",
        email: inputData?.email,
        email_2: "",
        address_1: "",
        address_2: "",
        full_address: "fulladdress",
        city: "",
        state: "",
        zip_code: "",
        source_id: 64,
        agent_id: "",
        listing_agent: "",
        company_attorney: "",
        loan_officer: "",
        insurance_agent: "",
        label: "label",
        notes: "",
        price_range: "",
        target_city: "",
        closing_date: "",
        bedroom: "",
        bathroom: "",
        garage: "",
        custom_tag: "",
        nurture: "",
        tag: "",
        summary: "",
        openfield1: "",
        openfield2: "",
        openfield3: "",
        openfield4: "",
        openfield5: "",
        openfield6: "",
        openfield7: "",
        openfield8: "",
        openfield9: "",
        openfield10: "",
        is_active: "Y",
        is_arch: "N",
        is_verified: "N",
        created_by: visintingDetail.created_by


      }

      CreateLeadWithoutTokenService(data)
        .then((res) => {
          if (res.status === 200) {
            setOpen(true)
            setSeverity("success")
            setSnackMsg("Successfully added!")
            setSubmitLoader(false)
            setIsLoading(false)

            setTimeout(() => {
              setOpen(false)
            }, 3000);

          }
        })
        .catch((err) => {
          setOpen(true)
          setSeverity("error")
          setSnackMsg("Error!")
          setSubmitLoader(false)
          //.log("error", err);
          setIsLoading(false)

        });
    }

  }


  useEffect(() => {
    getUserListingByKastnameAPI(visintingDetail?.kast_name)
      .then((res) => {
        setLisitng_item(res?.data?.listings);
      })
      .catch((err) => {
        console.log("err lisitng", err);
      });
  }, [visintingDetail]);


  useEffect(() => {
    const currentTime = new Date().getTime();
    const storedNotificationTime = localStorage.getItem('notification');
    if (storedNotificationTime === null) {
      // Set initial notification time
      sendNotification(currentTime)
    } else {
      // Calculate time difference
      const difference = currentTime - parseInt(storedNotificationTime);
      const differenceInMinutes = difference / (1000 * 60);
      if (differenceInMinutes >= 5) {
        // Update notification time and log
        sendNotification(currentTime)
      }
    }

  }, [visintingDetail])

  const sendNotification = async (currentTime) => {
    if (visintingDetail) {
      console.log("Function called for the aPI", visintingDetail)
      localStorage.setItem('notification', currentTime.toString());
      const respon = await sendNotificationAPI(visintingDetail.created_by)
    }
  }

  //#endregion#

  const getCardDetails = async () => {
    setLoading(true);
    const cardId = await GetCardByNameService(card_id);
    GetCardByIdService(cardId.data.data[0].id)
      .then((res) => {

        if (res.status === 200) {
          setVisintingDetail(res.data.data);
          document.documentElement.style.setProperty('--primary', res.data.data.color);
          setMetaTag({
            title: res.data.data.first_name + "'s Kast",
            description: res.data.data?.tagline ?? ""
          })
          setLoading(false);

        }
      })
      .catch((err) => {
        alert("please enter a valid Kast link")
        setLoading(false);

      });

  };

  useEffect(() => {
    getCardDetails();
  }, []);

  const getFunnelData = (data) => {
    const funnelname = data.split("/")[0];
    if (funnelname === "funnel-1") {
      return 1;
    }
    if (funnelname === "funnel-2") {
      return 2;
    }
    if (funnelname === "funnel-3") {
      return 3;
    }
  };

  const hexToRgba = (hex, opacity) => {
    hex = hex.replace('#', '');
    let r = parseInt(hex.substring(0, 2), 16);
    let g = parseInt(hex.substring(2, 4), 16);
    let b = parseInt(hex.substring(4, 6), 16);
    let a = opacity || 1;

    return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + a + ')';
  }

  const handleChangeInputValue = (e) => {
    setInputData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
    // //.log(inputData);
  };

  React.useEffect(() => {
    getFunnelDetails()
  }, [visintingDetail])



  const getFunnelDetails = async () => {
    //  need tp get user id 
    const id = visintingDetail?.created_by
    await addScript(id)
    const response = await getFunnelDetailsByAPI(id);
    setBackFunnel(response.data.funnel)
    getSourceFromDetails(response.data.funnel)
  }

  const getSourceFromDetails = (funnel) => {
    let compareDetails = dummyFunnelData;
    // BackFunnel
    setMainFunnelData({})
    for (let i = 0; i < funnel.length; i++) {
      compareDetails[funnel[i].funnel_link] = {
        title: funnel[i].funnel_name,
        uri: funnel[i].hero_image_upload,
        data: {
          url: funnel[i].funnel_link,
          title: funnel[i].funnel_type,
          type: "R",
          is_active: "Y",
        },
      }
    }
    setMainFunnelData(compareDetails);

  }


  const addScript = async (id) => {
    const ids = [172, 269, 2]
    if (card_id != "sethlevin") {

      const reponse = await getSubscribeSerive(id);
      const serviceArr = reponse.data.data.map(item => item.service_type);

      const scriptRes = await getScriptByUser(id)

      // if (id == 2 && serviceArr.includes("kb")) {

      //   const typebotInitScript = document.createElement("script");
      //   typebotInitScript.type = "module";
      //   typebotInitScript.innerHTML = `
      //       import Typebot from 'https://cdn.jsdelivr.net/npm/@typebot.io/js@0.2.85/dist/web.js'
      //       Typebot.initBubble({
      //         typebot: "john-wayne-default-sequence-zkt94bu",
      //         apiHost: "https://talk.kast.biz",
      //         previewMessage: {
      //           message: "Hi!",
      //           avatarUrl:
      //             "https://bizooda.s3.us-east-2.amazonaws.com/folder_name/HfKaG2SWz8Jw1tLbMVzZpCZNnRqbgTfS7IR8nZmM.jpg",
      //         },
      //         theme: {
      //           button: { backgroundColor: "#4A8BB2" },
      //           chatWindow: { backgroundColor: "#2e2c28" },
      //         },
      //       });
      //       `;
      //   document.body.append(typebotInitScript);

      // }

      if (scriptRes?.data?.script?.length == 0) {

        if (serviceArr.includes("kb")) {
          const typebotInitScript = document.createElement("script");
          typebotInitScript.type = "module";
          typebotInitScript.innerHTML = `
            import Typebot from 'https://cdn.jsdelivr.net/npm/@typebot.io/js@0.2.60/dist/web.js'

            Typebot.initBubble({
              typebot: 'lead-gen-bot-for-kast-prod-zn7kl69',
              apiHost: "https://talk.kast.biz",
              previewMessage: {
                message: "I have a question for you!",
                avatarUrl: "{{avatarURL}}",
              },
              prefilledVariables: {
                userid:${id}
          },
              theme: {
                button: { backgroundColor: "#4A8BB2" },
                chatWindow: { backgroundColor: "#deeef7" },
              },
            });`;
          document.body.append(typebotInitScript);

        }
      } else {


        // adding typebot when no script is added 
        const typebotscript = scriptRes.data.script.filter(_oi => _oi.script.includes("typebot"))
        if (serviceArr.includes("kb") && typebotscript.length) {
          const typebotInitScript = document.createElement("script");
          typebotInitScript.type = "module";
          typebotInitScript.innerHTML = `
            import Typebot from 'https://cdn.jsdelivr.net/npm/@typebot.io/js@0.2.60/dist/web.js'

            Typebot.initBubble({
              typebot: 'lead-gen-bot-for-kast-prod-zn7kl69',
              apiHost: "https://talk.kast.biz",
              previewMessage: {
                message: "I have a question for you!",
                avatarUrl: "{{avatarURL}}",
              },
              prefilledVariables: {
                userid:${id}
          },
              theme: {
                button: { backgroundColor: "#4A8BB2" },
                chatWindow: { backgroundColor: "#deeef7" },
              },
            });`;
          document.body.append(typebotInitScript);
        }

        // adding typebot when script is added 
        if (serviceArr.includes("kb") && !typebotscript.length) {
          const typebotInitScript = document.createElement("script");
          typebotInitScript.type = "module";
          typebotInitScript.innerHTML = `
            import Typebot from 'https://cdn.jsdelivr.net/npm/@typebot.io/js@0.2.60/dist/web.js'

            Typebot.initBubble({
              typebot: 'lead-gen-bot-for-kast-prod-zn7kl69',
              apiHost: "https://talk.kast.biz",
              previewMessage: {
                message: "I have a question for you!",
                avatarUrl: "{{avatarURL}}",
              },
              prefilledVariables: {
                userid:${id}
          },
              theme: {
                button: { backgroundColor: "#4A8BB2" },
                chatWindow: { backgroundColor: "#deeef7" },
              },
            });`;
          document.body.append(typebotInitScript);
        }



        for (let i = 0; i < scriptRes?.data?.script?.length; i++) {

          if (scriptRes?.data?.script[i].script.includes("processwebsitedata")) {
            const responseSS = await checkValidationForKastIDAPI(id);
            console.log("validation", responseSS)
            if (responseSS.validity) {
              console.log(scriptRes?.data?.script[i].script)
              const script = document.createElement('script');
              script.src = scriptRes?.data?.script[i].script;
              script.defer = true;
              document.head.appendChild(script);
            }
          } if (!scriptRes?.data?.script[i].script.includes("processwebsitedata")) {
            console.log(scriptRes?.data?.script[i].script)
            const script = document.createElement('script');
            script.src = scriptRes?.data?.script[i].script;
            script.defer = true;
            document.head.appendChild(script);
          }


        }

      }

    }
  }



  React.useEffect(() => {

    if (card_id == "sethlevin") {
      const typebotInitScript = document.createElement("script");
      typebotInitScript.type = "module";
      typebotInitScript.innerHTML = `import Typebot from 'https://cdn.jsdelivr.net/npm/@typebot.io/js@0.2.60/dist/web.js'

      Typebot.initBubble({
        typebot: "seth-levin-full-sequence-x1bjgma",
        apiHost: "https://talk.kast.biz",
        theme: {
          button: { backgroundColor: "#4A8BB2" },
          chatWindow: { backgroundColor: "#deeef7" },
        },
      });`;
      document.body.append(typebotInitScript);

    }
  }, [visintingDetail])


  // visintingDetail?.social_details
  const socialLinks = visintingDetail?.social_details;

  const filteredLinks = socialLinks?.filter(link => ['I', 'F', 'L', 'T'].includes(link.type));

  const uniqueLinks = Array.from(new Set(filteredLinks?.map(link => link.url)))
    .map(url => filteredLinks?.find(link => link.url === url));


  return (
    <>

      <Helmet>
        <title>{metaTag?.title}</title>
        <meta name="description" content={metaTag?.description} />

        {/* {visintingDetail?.created_by == 2 ? <script src="//code.tidio.co/dogymkvwwyfvswjzyixloe975ulnwjon.js" async></script> : null} */}
      </Helmet >
      {loading === false ? <FlotingPopup CardDetails={visintingDetail} /> : null}
      {loading === true ? (
        <div style={{
          height: "100%",
          placeItems: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh"
        }}>
          <CircularProgress />

        </div>

      ) : (

        visintingDetail?.theme === "2" ? <KastPreview visintingDetail={visintingDetail} mainFunnelData={mainFunnelData} /> :
          visintingDetail?.theme === "3" ? <LightLandingPage visintingDetail={visintingDetail} mainFunnelData={mainFunnelData} /> :
            <div
              className="vistingContainer"
              style={{
                // height: "100vh"
              }}
            >
              <div
                className="visting-card"
                style={{
                  backgroundColor: "#fff", borderRadius: 10,
                }}
              >
                <div className="visting-header"
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      fontFamily: 'Nunito',
                      fontWeight: 500,
                      fontSize: "30px",
                      textTransform: "uppercase",
                      color: "white",
                      textAlign: "center",
                      lineHeight: 1,
                      margin: 20,
                      paddingBottom: 100
                    }}
                  >
                    {" "}
                    {visintingDetail?.company_name ?? "Alexiant"}
                  </div>

                </div>
                <div style={{ padding: "20px" }}>
                  <div className="visting-profile">
                    <div className="profile-avatar" style={{ border: `2px solid ${visintingDetail?.color}`, marginBottom: 25 }}>
                      {/*  */}
                      <img
                        alt=""
                        src={
                          visintingDetail?.profile_photo
                            ? `${visintingDetail.profile_photo}`
                            : process.env.PUBLIC_URL + "/assests/Image/avatar.jpg"
                        }
                        width="100%"
                        height="100%"
                        style={{ borderRadius: 100, objectFit: "cover" }}

                      />

                    </div>

                    <div className="profile-info" >

                      <div style={{ width: "100%", marginTop: 5 }}>
                        <Grid container spacing={2}>
                          <Grid item xs={6} sx={{ paddingTop: 0 }}>
                            <div style={{ height: "100%", width: "100%", display: "flex", alignItem: "left", justifyContent: "flex-start" }}>
                              <img alt="" style={{ height: 50, width: 50, borderRadius: 10 }} src={visintingDetail?.background_photo
                                // ??process.env.PUBLIC_URL + "/assests/Image/visting-logo.png"
                              } />

                            </div>
                          </Grid>
                          <Grid item xs={6} sx={{}}>
                            <div style={{ height: "100%", width: "100%", display: "flex", alignItem: "right", justifyContent: "flex-end" }}>
                              <QRCode value={"https://kast.biz/" + visintingDetail?.kast_name} size={50} bgColor="#FFFFFF" fgColor="#000000" />

                            </div>
                          </Grid>
                        </Grid>
                      </div>


                      <div style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: 'row',
                        marginTop: 5,
                        marginBottom: 5
                      }}>
                        <div
                          style={{
                            paddingRight: 10,

                            fontWeight: 700,
                            fontSize: "24px",
                            color: `${visintingDetail?.color}`,
                            lineHeight: "113.9%",
                          }}
                        >
                          {visintingDetail?.first_name}

                        </div>

                        <div className="visiting-seprator" ></div>
                        <div
                          style={{
                            // marginTop: 20,
                            fontWeight: 600,
                            fontSize: "18px",
                            paddingLeft: 10,
                            color: `${visintingDetail?.color}`,
                          }}
                        >
                          {visintingDetail?.job_title}
                        </div>

                      </div>


                      <div className="visiting-divider" style={{ backgroundColor: visintingDetail?.color }}></div>

                      <div style={{
                        marginTop: 10, fontWeight: 600,
                        fontSize: "14px",
                        color: `${visintingDetail?.color}`,
                        lineHeight: "128.9%",
                        textAlign: "center",

                      }}>
                        {visintingDetail?.tagline ?? "Extraordinary Reach. Extraordinary Results."}
                      </div>
                      <hr style={{ width: "80%", textAlign: "center" }} />

                      {visintingDetail?.address ??
                        <div style={{
                          marginTop: 10, fontWeight: 400,
                          fontSize: "14px",
                          color: `black`,
                          lineHeight: "128.9%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          flexDirection: 'row',
                        }}>
                          <div style={{
                            display: "flex",
                            alignItems: "center",
                            color: `${visintingDetail?.color}`
                          }}>
                            {visintingDetail?.address ? <LocationIcon /> : ""}

                          </div>
                          <div style={{
                            display: "flex",
                            alignItems: "center",
                          }}>
                            {visintingDetail?.address}</div>

                        </div>}

                      <div style={{}}>
                        <div
                          style={{
                            marginTop: 15,
                            width: "100%",
                            fontWeight: 500,
                            fontSize: "14px",
                            display: "flex",
                          }}
                        >


                          <div className="contact-info">
                            <div style={{}}>
                              <a href={`tel:${visintingDetail?.mobile}`} style={{ textDecoration: "none", display: "flex", color: `${visintingDetail?.color}`, justifyContent: "center" }}>{visintingDetail?.mobile == "" || visintingDetail?.mobile == null ? null : <PhoneIconSvg />}&nbsp;&nbsp;{visintingDetail?.mobile}
                                &nbsp;&nbsp;{visintingDetail?.officePhone == "" || visintingDetail?.officePhone == null ? "" : <Telephone />}&nbsp;&nbsp; {visintingDetail?.officePhone != "" && visintingDetail?.officePhone != null ? visintingDetail?.officePhone : ""}   &nbsp;</a>
                            </div>
                            <a href={`mailto:${visintingDetail?.email}`} style={{ textDecoration: "none", color: `${visintingDetail?.color}`, display: "flex", flex: 1, justifyContent: "center" }}><MessageIcon />&nbsp;&nbsp;{visintingDetail?.email}</a>
                          </div>


                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="visting-icons">
                    <Grid
                      container
                      spacing={2}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {/*  */}
                      {visintingDetail?.company_website === "" ? null : (
                        <Grid item lg={2} xs={2}>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            <div className="social-icons" style={{ backgroundColor: `${visintingDetail?.color}` }}>
                              <a
                                href={visintingDetail?.company_website}
                                target="_blank"
                              >
                                <VisitingWebsite />
                              </a>
                            </div>
                            <div
                              style={{
                                marginTop: window.innerWidth > 414 ? 10 : 0,
                                fontWeight: 500,
                                fontSize: window.innerWidth > 414 ? "13px" : "10px",
                              }}
                            >
                              website
                            </div>
                          </div>
                        </Grid>
                      )}
                      {uniqueLinks?.map((data) => {
                        return data.type === "I" ? (
                          <Grid key={data.id} item lg={2} xs={2}>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                              }}
                            >
                              <div className="social-icons" style={{ backgroundColor: `${visintingDetail?.color}` }}>
                                <a href={data.url} target="_blank">
                                  <VisitingInsta />
                                </a>
                              </div>
                              <div
                                style={{
                                  marginTop: window.innerWidth > 414 ? 10 : 0,
                                  fontWeight: 500,
                                  fontSize: window.innerWidth > 414 ? "13px" : "10px",
                                }}
                              >
                                Instagram
                              </div>
                            </div>
                          </Grid>
                        ) : data.type == "F" ? (
                          <Grid key={data.id} item lg={2} xs={2}>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                              }}
                            >
                              <div className="social-icons" style={{ backgroundColor: `${visintingDetail?.color}` }}>
                                <a href={data.url} target="_blank">
                                  <VisitingFacebook />
                                </a>
                              </div>
                              <div
                                style={{
                                  marginTop: window.innerWidth > 414 ? 10 : 0,
                                  fontWeight: 500,
                                  fontSize: window.innerWidth > 414 ? "13px" : "10px",
                                }}
                              >
                                Facebook
                              </div>
                            </div>
                          </Grid>
                        ) : data.type == "L" ? (
                          <Grid key={data.id} item lg={2} xs={2}>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                              }}
                            >
                              <div className="social-icons" style={{ backgroundColor: `${visintingDetail?.color}` }}>
                                <a href={data.url} target="_blank">
                                  <VisitingLinkdin />
                                </a>
                              </div>
                              <div
                                style={{
                                  marginTop: window.innerWidth > 414 ? 10 : 0,
                                  fontWeight: 500,
                                  fontSize: window.innerWidth > 414 ? "13px" : "10px",
                                }}
                              >
                                Linkedin
                              </div>
                            </div>
                          </Grid>
                        ) : data.type == "T" ? (
                          <Grid key={data.id} item lg={2} xs={2}>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                              }}
                            >
                              <div className="social-icons" style={{ backgroundColor: `${visintingDetail?.color}` }}>
                                <a href={data.url} target="_blank">
                                  <VisitingTwitter />
                                </a>
                              </div>
                              <div
                                style={{
                                  marginTop: window.innerWidth > 414 ? 10 : 0,
                                  fontWeight: 500,
                                  fontSize: window.innerWidth > 414 ? "13px" : "10px",
                                }}
                              >
                                Twitter
                              </div>
                            </div>
                          </Grid>
                        )
                          : (
                            ""
                          );
                      })}
                    </Grid>
                  </div>

                  <div className="visting-hr"></div>
                  <div style={{ marginTop: 10 }}>
                    {visintingDetail?.social_details?.map((item) => {
                      if (item.type === "E") {
                        return (
                          <div key={item.id} class="alert-external" style={{ backgroundColor: hexToRgba(visintingDetail?.color, 0.3), borderRadius: "100px", flexDirection: "row", display: "flex" }}>
                            <div style={{ flex: 1, justifyContent: "center", alignSelf: "center", alignItems: "center" }}>
                              <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect x="14.9316" width="6.75286" height="9.62924" rx="3.37643" transform="rotate(48.2618 14.9316 0)" stroke={visintingDetail?.color} />
                                <rect x="7.18506" y="7.55078" width="6.75286" height="9.62924" rx="3.37643" transform="rotate(48.2618 7.18506 7.55078)" stroke={visintingDetail?.color} />
                                <rect x="12.3667" y="4.55469" width="3.03038" height="10.8313" transform="rotate(44.6797 12.3667 4.55469)" fill="#DEDAE8" />
                                <path d="M13.1777 5.75391L6.38916 12.7421" stroke={visintingDetail?.color} strokeLinecap="round" />
                              </svg>
                            </div>
                            <div style={{ flex: 5, padding: 10, margin: 5, justifyContent: "center", alignSelf: "center", alignItems: "center" }}>
                              <a class="external-name" style={{ color: visintingDetail?.color, fontWeight: "500", textAlign: "left", padding: 10 }} href={item.url}>
                                {item.title}
                              </a>
                            </div>
                            <div style={{ flex: 1, justifyContent: "center", alignSelf: "center", alignItems: "center" }} >
                            </div>
                          </div>
                        );
                      }
                    })}
                  </div>

                  <div className="visting-hr" style={{ marginTop: 10 }}></div>
                  <div className="visiting-funnel" style={{ marginTop: "30px", cursor: "pointer" }}>
                    {visintingDetail?.social_details.filter(_i => _i.type == "K").length > 0 ? <h4>Listings & Open Houses</h4> : null}

                    <Grid container spacing={2}>

                      {lisitng_item?.length > 0 && visintingDetail?.social_details?.map((item) => {
                        if (item.type === "K" && lisitng_item?.filter(
                          (i) => i.listing_title === item.title
                        ).length > 0) {
                          let data_detail = lisitng_item?.filter(
                            (i) => i.listing_title === item.title
                          )[0];
                          return (!data_detail?.listing_title ? null :
                            <Grid
                              item
                              xs={6}
                              key={data_detail.id}
                              lg={6}
                              onClick={() => {
                                window.location.href = "/" + visintingDetail?.first_name + `/listing/${data_detail?.listing_title}`
                              }}
                            >

                              <div className="visting-funnel-card">
                                <div
                                  className="visiting-funnel-img"
                                  style={{ width: "100%" }}
                                >

                                  {data_detail?.holding_open_house == "1" && moment(data_detail?.date, 'DD/MM/YYYY').isAfter(moment()) ?
                                    <Box
                                      sx={{
                                        background: "linear-gradient(120deg, #6916E1,#2220F3)",
                                        width: 70,
                                        p: 1,
                                        height: 20,
                                        borderTopRightRadius: 10,
                                        borderBottomLeftRadius: 10,
                                        position: 'absolute'
                                      }}
                                    >
                                      <Typography
                                        fontSize={isMobile ? 8 : 12}
                                        sx={{
                                          justifyContent: "center",
                                          alignSelf: "center",
                                        }}
                                        color="#fff"
                                      >
                                        {"Open House"}
                                      </Typography>
                                    </Box> : null}

                                  <img
                                    alt="listing-img"
                                    src={
                                      data_detail?.image.split(",")[0] ?? ""
                                    }
                                    // height={window.innerWidth > 991 ? "180px" : "120px"}
                                    width="100%"
                                    style={{ borderRadius: 2, objectFit: "cover", aspectRatio: 1.8 }}
                                  />
                                </div>
                                <div style={{ marginTop: 10 }}>
                                  <div
                                    style={{
                                      fontWeight: 300,
                                      fontSize: "12px",
                                    }}
                                  >
                                    {item?.title}
                                  </div>
                                </div>
                              </div>
                            </Grid>
                          );
                        }
                      })}
                    </Grid>
                  </div>
                  <div className="visting-hr" style={{ marginTop: 10 }}></div>

                  <div className="visiting-funnel" style={{ marginTop: "30px", cursor: "pointer" }}>
                    {visintingDetail?.social_details.filter(_i => _i.type == "R").length > 0 ? <h4>Free Downloads</h4> : null}

                    {Object.keys(mainFunnelData).length == 0 ? null :
                      <Grid container spacing={2}>
                        {visintingDetail?.social_details?.map((item) => {
                          if (item.type === "R") {
                            return (
                              <Grid
                                item
                                xs={6}
                                lg={6}
                                onClick={() => {
                                  window.location.href = "/" + visintingDetail?.kast_name + `/${mainFunnelData[item.url.includes("/") ? item.url.split("/")[0] : item.url].data.url}/`
                                  // getFunnelData(item.url) == 1
                                  //   ? "/" + visintingDetail?.kast_name + "/HomeSellerChecklist/"
                                  //   : getFunnelData(item.url) == 2
                                  //     ? "/" + visintingDetail?.kast_name + "/Buying101Guide/"
                                  //     : getFunnelData(item.url) == 3
                                  //       ? "/" + visintingDetail?.kast_name + "/SellingInsiderSecrets/"
                                  //       : "";
                                }}
                              >
                                <div className="visting-funnel-card">
                                  <div
                                    className="visiting-funnel-img"
                                    style={{ width: "100%", padding: 0 }}
                                  >

                                    <img
                                      alt="funnel-img"
                                      src={
                                        mainFunnelData[item.url.includes("/") ? item.url.split("/")[0] : item.url]?.uri
                                      }
                                      // height={window.innerWidth > 991 ? "180px" : "120px"}
                                      width="100%"
                                      style={{ borderRadius: 2, aspectRatio: 0.8 }}
                                    />
                                  </div>
                                  <div style={{ marginTop: 10 }}>
                                    <div
                                      style={{
                                        fontWeight: 400,
                                        fontSize: "12px",
                                      }}
                                    >
                                      {mainFunnelData[item.url.includes("/") ? item.url.split("/")[0] : item.url]?.title}
                                    </div>
                                    <div
                                      style={{
                                        fontWeight: 600,
                                        fontSize: "14px",
                                        color: "red",
                                        marginTop: 10
                                      }}
                                    >
                                      <svg width="14px" height="14px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <g id="Interface / Download"> <path id="Vector" d="M6 21H18M12 3V17M12 17L17 12M12 17L7 12" stroke="#e32400" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path> </g> </g></svg>
                                      Download
                                    </div>
                                  </div>
                                </div>
                              </Grid>
                            );
                          }
                        })}
                      </Grid>}
                  </div>

                  <div style={{ justifyContent: "center", alignSelf: "flex-end", display: "flex" }}>
                    <Grid container spacing={2} justifyContent={"flex-start"} alignSelf={"flex-start"}>
                      <Grid item lg={8} xs={8}>

                        <div className="login-input" style={{}}>
                          <div style={{ paddingLeft: 20 }}>
                            <MessageIcon />
                          </div>

                          <div style={{ width: "100%", marginLeft: "21px" }}>
                            <Input
                              className="input"
                              disableUnderline
                              type="text"
                              name="email"
                              placeholder="Enter Email ID"
                              fullWidth
                              // onChange={handleChangeInputValue}
                              onChange={handleChangeInputValue}
                            />
                          </div>
                        </div>

                        <div style={{}}>
                          {inputError?.email_error && (
                            <Typography
                              variant="caption"
                              sx={{
                                color: "red",
                              }}
                            >
                              {inputError?.email_error}
                            </Typography>
                          )}
                        </div>
                      </Grid>

                      <Grid item xs={3} lg={4} sx={{ display: "flex", }}>
                        <div style={{ marginTop: 5 }}>
                          <Button name="Submit" sx={{ background: "linear-gradient(40deg, #4643BE , #8A58F5 ) !important", color: "white", height: "45px !important", marginTop: 1, width: isMobile ? "80%" : "100%" }}
                            // fullWidth
                            // startIcon={props.button_icon}
                            // endIcon={props.endIcon}
                            variant="contained"
                            onClick={submitLead}
                          >{isLoading ? <CircularProgress size={20} /> : "Submit"}</Button>
                        </div>
                      </Grid>
                    </Grid>
                  </div>

                  <div style={{
                    marginTop: 15, textAlign: "right", fontWeight: 400,
                    fontSize: "12px",
                    lineHeight: "20.54%",
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: 'center',
                    // textAlign:"right"
                  }}>

                    Made with &nbsp;<HeartEmoji />&nbsp;  by Kast.biz
                  </div>


                </div>
              </div >
              <div style={{ width: "100vh" }}>
                <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} open={open} action={
                  <React.Fragment>
                    <IconButton
                      size="small"
                      aria-label="close"
                      color="inherit"
                      onClick={() => {
                        setOpen(false)
                      }}
                    >
                      x
                    </IconButton>
                  </React.Fragment>
                } autoHideDuration={6000} sx={{ zIndex: 100 }}>
                  <Alert severity={severity} sx={{ width: '100%' }}>
                    {snackMsg}
                  </Alert>
                </Snackbar>
              </div>
            </div >
      )
      }
    </>
  );
}

export default VisitingCard;
